import React, { useState, useEffect } from "react";
import { Line } from "rc-progress";
import images from "../images/images";
import "./singleProgressBarComponent.scss";
import BackNextActionButtonComponent from "../backNextActionButtonComponent";
import Aries from "../../locale/en-Us.json";
import ButtonGroupFooter from "../buttonGroupFooter";
const SingleProgressBarComponent = ({
  initialPercent = 10,
  onResponse,
  label,
  heading,
  loadingText,
  error,
  backStep,
  nextStep,
  showNext,
}) => {
  const [percent, setPercent] = useState(initialPercent);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const id = setInterval(() => {
      setPercent((prevPercent) => {
        if (prevPercent >= 90) {
          clearInterval(id);
          return prevPercent;
        }
        return Math.min(90, prevPercent + 1);
      });
    }, 500);
    setIntervalId(id);

    return () => clearInterval(id);
  }, []);

  const buttonGroup = {
    left: [],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        className: "back-button",
        fn: backStep,
        imageLink: images["arrow-left.svg"],
      },
      // {
      //   text: Aries.BUTTON_GLOBAL_NEXT,
      //   rightImageLink: images["arrow-right.svg"],
      //   className: "primary-button ",
      //   fn: nextStep,
      // },
    ],
  };
  useEffect(() => {
    if (typeof onResponse === "function") {
      onResponse()?.then(() => {
        clearInterval(intervalId);
        setPercent(100);
      });
    }
  }, [onResponse, intervalId]);

  return (
    <div className="generator-progress-wrapper">
      <div className="wrapper-label">
        <img
          className="user-icon"
          src={images["aistra-vaa-avatar-1.svg"]}
          loading="lazy"
          alt="User icon"
        />
        <div
          className="label"
          dangerouslySetInnerHTML={{ __html: label }}
        ></div>
      </div>

      <div className="progress-bar-box">
        {heading && <div className="sub-title">{heading}</div>}
        <Line
          percent={initialPercent !== 100 ? percent : initialPercent}
          strokeWidth={1}
          trailWidth={1}
          strokeColor={"#00A97D"}
        />
        {error ? (
          <div className="download-wrapper">
            <div className="download-info" style={{ color: "red" }}>
              <img
                className="download-icon"
                src={images["document-download.svg"]}
                loading="lazy"
                alt="download icon"
              />
              {error}
            </div>
          </div>
        ) : (
          <div className="info-wrapper">
            <div className="info">{loadingText}</div>
            <div className="percent">
              {initialPercent !== 100 ? percent : initialPercent}%{" "}
              {Aries.PROGESS_BAR_TO_COMPLETE_TEXT}
            </div>
          </div>
        )}
      </div>
      {/* <BackNextActionButtonComponent
        showBack={true}
        disableBack={false}
        disableNext={initialPercent !== 100 ? true : false}
        showNext={showNext}
        backActionStep={backStep}
        nextActionStep={nextStep}
        // nextbtnName={nextbtnName}
        backIcon={<img src={images["arrow-left.svg"]} alt="left" />}
        nextIcon={<img src={images["arrow-right.svg"]} alt="right" />}
      /> */}
      <ButtonGroupFooter
        className="fixed-button-group"
        buttonGroupObject={buttonGroup}
      />
    </div>
  );
};

export default SingleProgressBarComponent;
