export const apiBaseUrl = process.env.REACT_APP_BASE_URL; // Replace with your API base URL
// export const syncfusionSpreedSheetBaseUrl =
//   "https://u2fd0ixpc2.execute-api.me-central-1.amazonaws.com/Prod/api/spreadsheet/";
// export const syncfusionDocumentBaseUrl =
//   "https://3bn7u5s1gb.execute-api.me-central-1.amazonaws.com/Prod/api/documenteditor/";
export const syncfusionSpreedSheetBaseUrl =
  process.env.REACT_APP_SYNCFUSION_EXCEL_URL + "/api/spreadsheet/";

export const syncfusionDocumentBaseUrl =
  process.env.REACT_APP_SYNCFUSION_DOC_UR + "/api/documenteditor/";

const apiRequest = async (
  url,
  method = "GET",
  storedUserData,
  data = null,
  type,
  apiRequestId = null
) => {
  const selectedLLM = localStorage.getItem("selectedLLM");
  const editDefaultPrompt = localStorage.getItem("editDefaultPrompts");
  const abortController = new AbortController();
  let headers;
  if (storedUserData && storedUserData.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + storedUserData.token,
      "llm-model": selectedLLM || "",
      apiRequestId: apiRequestId,
      "override-prompts": editDefaultPrompt,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      "llm-model": selectedLLM || "",
      apiRequestId: apiRequestId,
      "override-prompts": editDefaultPrompt,
    };
  }

  const apiUrl = `${apiBaseUrl}/${url}`;
  try {
    const config = {
      method,
      headers,
      body: data ? (type === "formData" ? data : JSON.stringify(data)) : null,
    };

    const response = await fetch(apiUrl, {
      ...config,
      signal: abortController.signal,
    });
    const responseHeaders = response?.headers;
    if (responseHeaders && responseHeaders.get("llm-prompt")) {
    }
    const responseData = await response.json();
    if (!response.ok) {
      if (response.status === 403 || response.status === 401) {
        // localStorage.clear();
        // window.location.href = "/";
      }
      return responseData;
      throw new Error(JSON.stringify(responseData));
    }
    return responseData;
  } catch (error) {
    return error;
    console.error("An error occurred", error);
  }
  // No need to return an abort function here
};

export default apiRequest;
