import { Table } from "antd";
import { decoderStatusTrackTableColumns } from "./helper";
import Aries from "../../locale/en-Us.json";
import images from "../../components/images/images";
import ButtonGroupFooter from "../../components/buttonGroupFooter";
import { downloadBase64File, tableLoading } from "../../utils/utils";
import API from "../../utils/api/api";
import { useDispatch } from "react-redux";
import { setNotification } from "../../redux/reducers";
import { notificationTypes } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DecoderDataUploadModal from "../../components/model/decoderDataUploadModal";

export const DecoderStatusTrackSheetTable = ({
  backStep,
  nextStep,
  handleOpenSheet,
  uploadDecoderSheet,
  decoderTrackerData,
  companyName,
  projectId,
  redirect,
  editAllowed,
  uploadTrialBalanceSheet = () => {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showFlowSelectModal, setShowFlowSelectModal] = useState(false);

  const exportDecoderSheet = async () => {
    const url = `v1/decoder/${projectId}/decoder/download`;
    const response = await API({
      method: "GET",
      path: url,
      dispatch,
    });
    const file = downloadBase64File(response?.data);
    if (file) {
      dispatch(
        setNotification({
          type: notificationTypes.SUCCESS,
          message: "File Downloaded Successfully",
          placement: "bottom",
        })
      );
    }
  };

  const finishDecoderProcess = () => {
    navigate("/");
    dispatch(
      setNotification({
        type: notificationTypes.SUCCESS,
        message: Aries.DECODER_TOAST_FINALIZE_SUCCESS,
        placement: "bottom",
      })
    );
  };

  const tablesConfig = [
    {
      title: "Trial Balance",
      dataSource: decoderTrackerData?.trialBalance,
      type: "TRIAL_BALANCE",
      buttonText: decoderTrackerData?.trialBalance?.[0]?.action,
    },
    {
      title: "Balance Sheets",
      dataSource: decoderTrackerData?.balanceSheets,
      type: "BALANCE_SHEET",
    },
    {
      title: "Income Statements",
      dataSource: decoderTrackerData?.incomeStatements,
      type: "INCOME_STATEMENT",
    },
    // Additional tables can be added here
  ];

  const buttonGroup = {
    left: [
      {
        text: Aries.BUTTON_GLOBAL_DOWNLOAD_FINALIZED_OUTPUT,
        className: "white-button",
        fn: exportDecoderSheet,
        imageLink: images["download.svg"],
      },
    ],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        className: "back-button",
        fn: backStep,
        imageLink: images["arrow-left.svg"],
        disabled: redirect,
      },
      {
        text: Aries.BUTTON_GLOBAL_FINISH,
        className: "primary-button",
        fn: finishDecoderProcess,
        disabled: !decoderTrackerData?.finishEnabled,
        rightImageLink: images["arrow-right.svg"],
      },
    ],
  };

  const getColumns = (type, buttonText) => {
    const baseColumns = decoderStatusTrackTableColumns(handleOpenSheet, type);

    if (type === "TRIAL_BALANCE") {
      // Find and modify the existing "Action" column
      return baseColumns.map((column) => {
        if (column.key === "action") {
          return {
            ...column,
            render: (_, record) => (
              <button
                className="primary-button-border button"
                onClick={() => setShowFlowSelectModal(true)}
              >
                {buttonText || "Upload Data"} {/* Dynamic text */}
              </button>
            ),
          };
        }
        return column;
      });
    }

    return baseColumns;
  };

  return (
    <>
      <div className="decoder-track-header">
        <div className="decoder-track-header-title">Financials Decoder</div>
        <div className="decoder-engament-text">
          {" "}
          Engagement: <span>{companyName}</span>
        </div>
      </div>
      <div className="decoder-track-table-wrapper">
        {tablesConfig.map((table, index) => (
          <div key={index}>
            <div className="decoder-track-heading-wrapper">
              <h2>{table.title}</h2>
              <div className="button-group-wrapper">
                {index === 0 && (
                  <>
                    <button
                      className="button primary-button"
                      //onClick={uploadDecoderSheet}
                      onClick={() => setShowFlowSelectModal(true)}
                      disabled={!editAllowed}
                    >
                      {Aries.DECODER_UPLOAD_DATA_BUTTON}
                    </button>
                    <button className="button primary-button" disabled>
                      Check SEC for more files
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="decoder-track-table">
              <Table
                dataSource={table.dataSource}
                columns={getColumns(table.type, table.buttonText)}
                pagination={false}
                rowHoverable={false}
                className={"table-container"}
                loading={tableLoading({
                  loading: decoderTrackerData ? false : true,
                  text: Aries.DECODER_TRACKER_LOADER,
                })}
                rowKey={(record) => record.docId}
                scroll={{ x: "1230px" }}
              />
            </div>
          </div>
        ))}
      </div>
      <DecoderDataUploadModal
        showModel={showFlowSelectModal}
        handleClose={() => setShowFlowSelectModal(false)}
        uploadTrialBalanceSheet={uploadTrialBalanceSheet}
        uploadDecoderSheet={uploadDecoderSheet}
      />
      <ButtonGroupFooter
        buttonGroupObject={buttonGroup}
        className={"fixed-button-group"}
      />
    </>
  );
};
