import API from "../../utils/api/api";
export const chatBotUrl = process.env.REACT_APP_BOT_URL;

export const chatStart = async () => {
  try {
    const url = chatBotUrl + `/chat/start`;

    const result = await API({
      method: "POST",
      path: url,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const getChatHistory = async (session_id) => {
  try {
    const url = chatBotUrl + `/chat/${session_id}/history`;

    const result = await API({
      method: "GET",
      path: url,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const sendChatMessage = async (session_id, data) => {
  try {
    const url = chatBotUrl + `/chat/${session_id}/message`;

    const result = await API({
      method: "POST",
      path: url,
      data: data,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};
