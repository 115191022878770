import React, { memo, useEffect, useMemo, useState, useRef } from "react";
import { createRoot } from "react-dom/client";
import isEqual from "lodash/isEqual";
import {
  SpreadsheetComponent,
  getIndexesFromAddress,
  getCell,
} from "@syncfusion/ej2-react-spreadsheet";
import { Empty, Skeleton, Popover } from "antd";
import { ReactTyped } from "react-typed";
import "./sheetComponent.scss";
import { syncfusionSpreedSheetBaseUrl } from "../api/api";
import { focus } from "@syncfusion/ej2-react-spreadsheet";
import API, { abortAll } from "../../utils/api/api";
import FallbackPlaceholder from "../../utils/components/fallback";
import { Select } from "antd";
import images from "../images/images";
import { imagesIconarr } from "./signImages";
const { Option } = Select;
function SheetComponent({
  getValueOnClick = () => {},
  getCellIndexValue = () => {},
  handleSaveSheetCb = () => {},
  file,
  spreadsheetRef,
  loading = true,
  loadingMessage = "",
  className = "",
  onOpenActionCb = () => {},
  handleHideRowsOnSheetOpen = () => {},
  beforeSaveCb = () => {},
  saveCompleteCb = () => {},
  showExportButton = false,
  activeSheet = () => {},
  showBottomTabs = true,
  sheetIndex = 0,
  enableVirtualization = false,
  loadingsheetStatus = () => {},
  disableMenu = true,
  highlightCellValue = [1, 1],
}) {
  // const scrollSettings = {
  //   isFinite: true,
  //   enableVirtualization: enableVirtualization,
  // };
  const disableheader = false;
  const [isSheetOpening, setIsSheetOpening] = useState(false);
  const [openFileResult, setOpenFileResult] = useState(null);
  const [openFailed, setOpenFailed] = useState(false);
  const [abortControllerRef, setAbortControllerRef] = useState(useRef(null));
  useEffect(() => {
    if (file && file.file) {
      setOpenFileResult(null);
      openExcel({
        file: file.file,
        extension: "xlsx",
        password: "",
      });
    }
  }, [file?.file, spreadsheetRef]);

  const convertExcelDate = (serialDate) => {
    const excelEpoch = new Date(1899, 11, 30); // Excel's epoch date is Dec 30, 1899
    return new Date(excelEpoch.getTime() + serialDate * 24 * 60 * 60 * 1000);
  };

  useEffect(() => {
    highlightCell(highlightCellValue[0], highlightCellValue[1]);
  }, [highlightCellValue[0], highlightCellValue[1]]);

  // Format the JavaScript Date into a readable string
  // const formatDate = (date) => {
  //   return date.toLocaleDateString(undefined, {
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //   });
  // };
  // const customItems = [
  //   {
  //     prefixIcon: "e-custom-icon", // CSS class for your custom icon
  //     tooltipText: "Custom Function",
  //     click: () => {
  //       if (spreadsheetRef.current) {
  //         const spreadsheet = spreadsheetRef.current;
  //         const sheet = spreadsheet.getActiveSheet();
  //         const range = sheet.getUsedRange();

  //         // Example custom function: Sum all numbers in the used range
  //         let sum = 0;
  //         for (
  //           let row = range.rowIndex;
  //           row < range.rowIndex + range.rowCount;
  //           row++
  //         ) {
  //           for (
  //             let col = range.colIndex;
  //             col < range.colIndex + range.colCount;
  //             col++
  //           ) {
  //             const cellValue = sheet.getCell(row, col).value;
  //             if (typeof cellValue === "number") {
  //               sum += cellValue;
  //             }
  //           }
  //         }

  //         // Display the result
  //         spreadsheet.updateCell({ value: `Total Sum: ${sum}` }, "A1");
  //       }
  //     },
  //   },
  // ];
  const onClick = (args) => {
    let valuesArray = [];
    let allValuesArray = [];
    let index = getIndexesFromAddress(args.range);
    spreadsheetRef.current
      ?.getData("A" + (index[0] + 1) + ":" + "Z" + (index[0] + 1))
      .then((data) => {
        const displayTextsArray = [...data.values()].map((entry, i) => {
          const rowIndex = index[0];
          const colIndex = i;

          const cell = getCell(
            rowIndex,
            colIndex,
            spreadsheetRef.current?.getActiveSheet()
          );
          const displayText = spreadsheetRef.current?.getDisplayText(cell);
          return displayText || entry.value;
        });

        const valuesArray = displayTextsArray.filter(
          (value) => value !== undefined
        );
        getValueOnClick(valuesArray);
        getCellIndexValue(index, args, displayTextsArray); // Pass all values (including undefined if needed)
      });
  };

  const onActionComplete = (args) => {
    handleSaveSheetCb(args, getSheetFromJson);
  };

  const onOpenExcelComplete = (args) => {
    onOpenActionCb(args);
  };

  const onDataBoundComplete = (args) => {
    handleHideRowsOnSheetOpen();
  };

  const saveCompleteHandler = (args) => {
    saveCompleteCb(args, spreadsheetRef);
  };

  const getSheetFromJson = async () => {
    const json = await spreadsheetRef.current.saveAsJson();
    return json;
  };

  const fileMenuBeforeOpen = () => {
    // Disable the 'New' option in the File menu.
    spreadsheetRef.current?.enableFileMenuItems(
      ["New", "Open", "Print"],
      false,
      false
    );
  };
  // useEffect(() => {
  //   if (openFileResult) {
  //     onCreated();
  //   }
  // }, [openFileResult]);

  const onCreated = async () => {
    if (openFileResult.data.Workbook && openFileResult.data.Workbook.sheets) {
      spreadsheetRef.current.openFromJson({ file: openFileResult.data });
       handleSaveSheetCb({ action: "openSheet" }, getSheetFromJson);
    }
// <<<<<<< dev

    // console.log("sheetIndex", sheetIndex, spreadsheetRef.current);
    //spreadsheetRef.current.activeSheetIndex = sheetIndex;
// =======
    spreadsheetRef.current.activeSheetIndex = 0;
// >>>>>>> staging
    spreadsheetRef.current.showSheetTabs = showBottomTabs;
    spreadsheetRef.current.allowEditing = disableMenu;
    spreadsheetRef.current.enableRibbonTabs(
      ["Home", "Formula", "View"],
      disableheader
    );
    focus(spreadsheetRef.current.element);
    spreadsheetRef.current.hideToolbarItems("Data", [0, 1, 2, 3, 4]);
    spreadsheetRef.current.hideRibbonTabs([
      "Formulas",
      "View",
      "Insert",
      "File",
    ]);

    spreadsheetRef?.current?.addToolbarItems(
      "Data",
      [
        {
          id: "custombtn",
          tooltipText: "Custom Btn",
          template: appendDropdownBtn("custombtn"),
        },
      ],
      7
    );

    activeSheet();
  };

  const handleImageChange = (value) => {
    const image = imagesIconarr.find((img) => img.id === value);
    if (spreadsheetRef.current && image) {
      spreadsheetRef.current.insertImage([
        { src: image.url, width: 25, height: 25 },
      ]);
    }
  };
  const content = (
    <div className="sheet-custom-option-icons">
      {imagesIconarr.map((item) => (
        <img
          src={item.url}
          onClick={() => handleImageChange(item.id)}
          alt="icon"
        />
      ))}
    </div>
  );
  const appendDropdownBtn = (id) => {
    const dropdownElement = (
      <Popover placement="bottomRight" content={content}>
        <div className="sheet-custom-icon">
          <img src={images["omega-circle.svg"]} alt="Omega circle" /> Tick Sign
        </div>
      </Popover>
    );

    const container = document.createElement("div");
    container.id = id;
    const root = createRoot(container);
    root.render(dropdownElement);

    return container;
  };

  const beforeSaveHandler = (eventArgs) => {
    beforeSaveCb(eventArgs, spreadsheetRef);
  };

  const beforeOpenHandler = (eventArgs) => {
    eventArgs.cancel = true;
  };

  const openExcel = async (requestData) => {
    // abortAll();
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const abortController = new AbortController();
      setAbortControllerRef(abortController); // Update the ref correctly
      setIsSheetOpening(true);
      const url = syncfusionSpreedSheetBaseUrl + "open";
      const result = await API({
        method: "POST",
        path: url,
        data: JSON.stringify(requestData),
        disableOverridePrompts: true,
        // signal: abortController.signal, // Use the signal from the new controller
      });
      if (!result) {
        setOpenFailed(true);
      }
      setOpenFileResult(result);
      setIsSheetOpening(false);
      loadingsheetStatus(false);
    } catch (error) {
      setIsSheetOpening(false);
      loadingsheetStatus(true);
      console.error("Error in GET request:", error);
    }
  };

  const highlightCell = (rowIndex, colIndex) => {
    if (spreadsheetRef.current) {
      const sheet = spreadsheetRef.current;
      let cindex = colIndex; //colIndex > 10 ? colIndex - 5 : colIndex;
      let rIndex = rowIndex; //rowIndex - 5;
      console.log("highlightCell", cindex, rIndex);
      const highlight = `${String.fromCharCode(63 + cindex)}${rIndex + 1}`;
      const cellAddress = `${String.fromCharCode(65 + colIndex)}${rowIndex + 1}`;
      sheet.cellFormat({ backgroundColor: "#fff" });
      sheet.cellFormat({ backgroundColor: "#E6FBF6" }, cellAddress);
      sheet.goTo(highlight);
      sheet.selectRange(cellAddress);
    }
  };

  return (
    <div tabIndex="1" className={`spredSheet-container ${className}`}>
      {openFileResult ? (
        <SpreadsheetComponent
          select={onClick}
          ref={spreadsheetRef}
          actionComplete={onActionComplete}
          saveComplete={saveCompleteHandler}
          beforeSave={beforeSaveHandler}
          fileMenuBeforeOpen={fileMenuBeforeOpen}
          created={onCreated}
          // activeSheetIndex={sheetIndex}
          toolbar={[]}
          // height={600}
          //  enablePersistence
          allowOpen={true}
          openComplete={onOpenExcelComplete}
          dataBound={onDataBoundComplete}
          // openUrl={syncfusionSpreedSheetBaseUrl + "open"}
          // saveUrl={
          //   showExportButton ? syncfusionSpreedSheetBaseUrl + "save" : ""
          // }
          // scrollSettings={scrollSettings}
          allowScrolling={true}
        ></SpreadsheetComponent>
      ) : loading || isSheetOpening ? (
        <div className="loading-sheet">
          <div className="loading-skeleton">
            <Skeleton active />
            <ReactTyped
              strings={[loadingMessage]}
              typeSpeed={25}
              style={{ color: "var(--black-color)" }}
            />
            <Skeleton active />
          </div>
        </div>
      ) : openFailed ? (
        <FallbackPlaceholder />
      ) : (
        <EmptyView />
      )}
    </div>
  );
}

const EmptyView = () => {
  return (
    <div className="loading-sheet">
      <div className="loading-skeleton">
        <Empty />
      </div>
    </div>
  );
};

export default memo(SheetComponent, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
