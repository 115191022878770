import React from "react";
import ButtonGroupFooter from "../../components/buttonGroupFooter";

import Aries from "../../locale/en-Us.json";
import images from "../../components/images/images";

import CommanDecobarSheetComponent from "../../components/commanDecoderSheetComponent";
import { convertToUSDString } from "../../utils/utils";
import { setNotification } from "../../redux/reducers";
import { notificationTypes } from "../../utils/constants";
import { useDispatch } from "react-redux";
import API from "../../utils/api/api";

const DraftBalanceAndIncomeSheet = ({
  backStep,
  nextStep,
  draftSheetData,
  activeTab,
  loading,
  projectId,
  period,
}) => {
  const dispatch = useDispatch();

  const saveDecoderSheet = async (decoderSheetData, status, editAllowed) => {
    try {
      // if (!editAllowed) {
      //   backStep();
      //   return;
      // }
      //setIsLoadingSpin(true);
      const url = "v1/decoder/" + projectId + "/decode";
      const result = await API({
        method: "POST",
        path: url,
        data: {
          balanceSheet: decoderSheetData.balanceSheet,
          incomeStatementSheet: decoderSheetData.incomeStatementSheet,
          period: decoderSheetData.period,
          jsonStatus: status,
          period: period,
          type: "TRIAL_BALANCE",
        },
        dispatch,
      });
      if (result.data) {
        //setIsLoadingSpin(false);
        backStep("refreshTrackApi");
        if (status === "DECODED") {
          dispatch(
            setNotification({
              type: notificationTypes.SUCCESS,
              message: "Decoded Successfully",
              placement: "bottom",
            })
          );
          return;
        }
        dispatch(
          setNotification({
            type: notificationTypes.SUCCESS,
            message: "Saved Draft Successfully",
            placement: "bottom",
          })
        );
      } else {
        dispatch(
          setNotification({
            type: notificationTypes.ERROR,
            placement: "bottom",
          })
        );
        //setIsLoadingSpin(false);
      }
    } catch (error) {
      //setIsLoadingSpin(false);
      console.log(error);
    }
  };

  const buttonGroup = {
    left: [
      {
        text: Aries.BUTTON_GLOBAL_SAVE_DRAFT,
        className: "white-button",
        fn: () => saveDecoderSheet(draftSheetData, "DRAFTED", false),
        imageLink: images["save-add.svg"],
      },
    ],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        className: "back-button",
        fn: backStep,
        imageLink: images["arrow-left.svg"],
        //disabled: redirect,
      },
      {
        text: Aries.GLOBAL_FINALIZED_BUTTON,
        className: "primary-button",
        fn: () => {
          saveDecoderSheet(draftSheetData, "DECODED", false);
          nextStep();
        },
        disabled: !draftSheetData,
        rightImageLink: images["arrow-right.svg"],
      },
    ],
  };

  const jsonData = {
    table: {
      columns: [
        {
          title: draftSheetData?.[activeTab]?.mappedKey,
          dataIndex: "mappedKey",
          key: "mappedKey",
          editable: true,
          width: 400,
        },
        {
          title: "Current Period",
          dataIndex: "currentPeriod",
          key: "currentPeriod",
          editable: true,
          width: 180,
          render: (text) => convertToUSDString(Number(text)),
        },
        {
          title: "Previous Period",
          dataIndex: "previousPeriod",
          key: "previousPeriod",
          editable: true,
          width: 180,
          render: (text) => convertToUSDString(Number(text)),
        },

        {
          title: draftSheetData?.[activeTab]?.comment,
          dataIndex: "comment",
          key: "comment",
          width: 100,
        },
      ],

      dataSource:
        draftSheetData?.[activeTab]?.sheet?.rows?.map((row) => {
          if (!row) return null; // Skip invalid rows

          const transformRow = (rowData) => {
            const transformed = {
              id: rowData.id,
              key: rowData.id,
              mappedKey: rowData.mappedKey || "N/A",
              currentPeriod: rowData.values?.[0] || 0, // Default to 0 if undefined
              previousPeriod: rowData.values?.[1] || 0,
              comment: draftSheetData?.[activeTab]?.comment || "N/A",
            };

            // Only add the `children` property if it has valid data
            if (rowData.children?.length) {
              transformed.children = rowData.children.map(transformRow);
            }

            return transformed;
          };

          return transformRow(row);
        }) || [],
    },
  };

  return (
    <div className="draft-balance-and-income-sheet">
      <h3>
        Draft {activeTab === "balanceSheet" ? "Balance Sheet" : "Income Statement"}
      </h3>
      <CommanDecobarSheetComponent
        sheetTableData={jsonData}
        decoderSheetname={activeTab}
        editAllowed={false}
        //getUpdatedSheetData={getUpdatedBalanceSheetData}
      />
      <ButtonGroupFooter
        buttonGroupObject={buttonGroup}
        className={"fixed-button-group"}
      />
    </div>
  );
};

export default DraftBalanceAndIncomeSheet;
