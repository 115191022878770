import { statusDisplayMap } from "../../constants";
import "./tags.scss";

export const StatusChip = ({ status }) => {
  const getStatusStyles = (status) => {
    switch (status) {
      case "DONE":
      case "FINALIZED":
      case "COMPLETED":
        return {
          backgroundColor: "var(--gannt-progress-early-start)",
          borderColor: "var(--status-done-border)",
          color: "var(--Colors-SUCCESS-500)",
          dotColor: "var(--Colors-SUCCESS-500)",
        };
      case "WIP":
      case "IN_PROGRESS":
      case "IN PROGRESS":
      case "PROCESSING":
        return {
          backgroundColor: "var(--status-review-bg)",
          borderColor: "var(--status-review-border)",
          color: "var(--status-review-color)",
          dotColor: "var(--status-review-color)",
        };
      case "REVIEW":
      case "PENDING_MANAGER_REVIEW":
      case "PENDING_PARTNER_REVIEW":
      case "PENDING_EQR_REVIEW":
        return {
          backgroundColor: "var(--status-review-bg)",
          borderColor: "var(--status-review-border)",
          color: "var(--status-review-color)",
          dotColor: "var(--status-review-color)",
        };
      case "TO_BE_STARTED":
      case "YET TO START":
        return {
          backgroundColor: "var(--search-background)",
          borderColor: "var(--inputs-border-color)",
        };
      case "YET_TO_BEGIN":
        return {
          borderColor: "var(--Colors-TERTIARY-400, #FFC16D)",
          backgroundColor: " var(--Colors-TERTIARY-50, #FFF7ED)",
          dotColor: "var(--Colors-TERTIARY-400, #FFC16D)",
        };
      case "WARNING":
        return {
          backgroundColor: "var(--table-chip-bg)",
          borderColor: "var(--Colors-TERTIARY-400)",
          color: "var(--Colors-WARNING-400)",
          dotColor: "var(--Colors-WARNING-400)",
        };
      case "FAILED":
        return {
          backgroundColor: "var(--Colors-ERROR-50)",
          borderColor: "var(--Colors-ERROR-300)",
          color: "var(--button-red)",
          dotColor: "var(--button-red)",
        };
      case "STANDARDIZED":
        return {
          backgroundColor: "var(--Colors-PRIMARY-GREEN-50)",
          borderColor: "var(--main-color)",
          dotColor: "var(--main-color)",
        };
      case "SUCCESS":
      case "DRAFT SAVED":
      case "DRAFT_SAVED":
      case "DRAFTED":
        return {
          backgroundColor: "var(--Colors-PRIMARY-GREEN-50)",
          borderColor: "var(--main-color)",
          color: "var(--progress-high)",
          dotColor: "var(--Colors-PRIMARY-GREEN-400)",
        };
      default:
        return {
          color: "black",
          backgroundColor: "var(--search-background)",
          borderColor: "var(--inputs-border-color)",
          dotColor: "var(--inputs-border-color)",
        };
    }
  };
  const styles = getStatusStyles(status);

  return (
    <div className="global-status-chip" style={styles}>
      <span
        className="chip-dot"
        style={{ backgroundColor: styles.dotColor }}
      ></span>
      {statusDisplayMap[status] || status}
    </div>
  );
};
