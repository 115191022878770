import { configureStore } from "@reduxjs/toolkit";
import fiscalix from "../redux/reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["errorData", "notification", "loader"],
};

const persistedReducer = persistReducer(persistConfig, fiscalix);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializability
    }),
});

const persistor = persistStore(store);

export { store, persistor };
