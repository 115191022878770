import API from "../../utils/api/api";

export const getSheetNameApi = async (fileId, dispatch) => {
  try {
    const url = `v1/decoder/${fileId}/sheet-names`;

    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const getSheetGuidanceApi = async (sheetId, data, dispatch) => {
  try {
    const url = `/v1/decoder/${sheetId}/guidance`;

    const result = await API({
      method: "POST",
      path: url,
      data: data,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const getSheetGuidanceSubmitApi = async (sheetId, data, dispatch) => {
  try {
    const url = `v1/decoder/${sheetId}/guidance/submit`;

    const result = await API({
      method: "POST",
      path: url,
      data: data,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const getGuidanceRequiredApi = async (
  projectId,
  period,
  statementType,
  dispatch
) => {
  try {
    const url = `v1/decoder/${projectId}/guidance-required?period=${period}&statementType=${statementType}`;

    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const getuploadedSheetFeildApi = async (projectId, period, dispatch) => {
  try {
    const url = `/v1/decoder/${projectId}/financial-statement?period=${period}`;
    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });
    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};
export const getDecoderTrackerApi = async (projectId, dispatch) => {
  try {
    const url = `v1/decoder/${projectId}/tracker`;

    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const getDecoderSheetDataApi = async (
  projectId,
  period,
  statementType,
  dispatch
) => {
  try {
    const url = `v1/decoder/${projectId}/decode?period=${period}&statementType=${statementType}`;

    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

// Trail balance Decoder

export const getSheetTemplateApi = async (projectId, dispatch) => {
  try {
    const url = `/v1/decoder/${projectId}/trial-balance/sample`;

    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const postTrialBalanceSheetUploadApi = async (projectId, data, dispatch) => {
  try {
    const url = `v1/decoder/${projectId}/trial-balance/upload`;

    const result = await API({
      method: "POST",
      path: url,
      data: data,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const getTrialBalanceSheetValidationApi = async (projectId, dispatch) => {
  try {
    const url = `v1/decoder/${projectId}/trial-balance/validation`;

    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const getTrialBalanceMappingApi = async (projectId, dispatch) => {
  try {
    const url = `v1/decoder/${projectId}/trial-balance/metadata`;

    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const patchTrialBalanceMappingApi = async (projectId, metaData, dispatch) => {
  try {
    const url = `v1/decoder/${projectId}/trial-balance/metadata`;

    const result = await API({
      method: "PATCH",
      path: url,
      data: metaData,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const getDraftSheetsApi = async (projectId, dispatch) => {
  try {
    const url = `v1/decoder/${projectId}/trial-balance/sheet`;

    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};
