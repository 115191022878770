import { useState } from "react";
import {
  getSheetNameApi,
  getSheetGuidanceApi,
  getSheetGuidanceSubmitApi,
  getGuidanceRequiredApi,
  getuploadedSheetFeildApi,
  getSheetTemplateApi,
  postTrialBalanceSheetUploadApi,
  getTrialBalanceMappingApi,
  getTrialBalanceSheetValidationApi,
  getDraftSheetsApi,
} from "./api";
import { useDispatch } from "react-redux";

export const useGetSheetName = () => {
  const dispatch = useDispatch();
  const [sheetName, setSheetname] = useState([]);
  const getSheetName = async (file_id) => {
    const data = await getSheetNameApi(file_id, dispatch);
    return data;
  };
  return { getSheetName };
};

export const useGetSheetGuidance = () => {
  const dispatch = useDispatch();
  const [sheetGuidance, setSheetGuidance] = useState({});

  const getSheetguidance = async (sheetId, sheets) => {
    setSheetGuidance({});
    const data = await getSheetGuidanceApi(sheetId, sheets, dispatch);
    setSheetGuidance(data);
  };

  return { getSheetguidance, sheetGuidance, setSheetGuidance };
};

export const useGetSheetGuidanceSubmit = () => {
  const dispatch = useDispatch();
  const [submitedSheetReponse, setSubmitedSheetReponse] = useState({});
  const submitSheetguidance = async (sheetId, sheets) => {
    const data = await getSheetGuidanceSubmitApi(sheetId, sheets, dispatch);
    setSubmitedSheetReponse(data);
    return data;
  };

  return { submitedSheetReponse, submitSheetguidance };
};

// export const useGuidanceRequire = () => {
//   const dispatch = useDispatch();
//   const [guidanceRequired, setGuidanceRequired] = useState(false);
//   const getGuidanceRequired = async (project_id) => {
//     const data = await getGuidanceRequiredApi(project_id, dispatch);
//     setGuidanceRequired(data);
//   };
//   return { getGuidanceRequired, guidanceRequired };
// };

export const checkGuidanceRequire = async (project_id, period, statementType) => {
  const data = await getGuidanceRequiredApi(project_id, period, statementType);
  // if (data === true) {
  const feildData = await getuploadedSheetFeildApi(project_id, period);
  if (feildData) {
    const sheetname = await getSheetNameApi(feildData.id);
    return { feildData, sheetname, data };
  }
  // }
  // return false;
};

// Trail Balance

export const useGetSheetTemplate = () => {
  const dispatch = useDispatch();
  const [sheetTemplate, setSheetTemplate] = useState({});

  const getSheetTemplate = async (projectId) => {
    setSheetTemplate({});
    const data = await getSheetTemplateApi(projectId, dispatch);
    setSheetTemplate(data);
  };

  return { getSheetTemplate, sheetTemplate, setSheetTemplate };
};

export const useGetSheetValidation = () => {
  const dispatch = useDispatch();
  const [sheetValidationResponse, setSheetValidationResponse] = useState(null);

  const getTrialBalanceSheetValidation = async (projectId) => {
    setSheetValidationResponse(null);
    const data = await getTrialBalanceSheetValidationApi(projectId, dispatch);
    setSheetValidationResponse(data);
  };

  return {
    getTrialBalanceSheetValidation,
    sheetValidationResponse,
    setSheetValidationResponse,
  };
};

export const useGetTrailBalanceMapping = () => {
  const dispatch = useDispatch();
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setLoading] = useState(true);

  const getTrailBalanceMapping = async (projectId) => {
    setLoading(true);
    setMetaData(null);
    const data = await getTrialBalanceMappingApi(projectId, dispatch);
    setMetaData(data);
    setLoading(false);
  };

  return { getTrailBalanceMapping, metaData, setMetaData, metaDataLoading };
};

export const useGetDraftSheet = () => {
  const dispatch = useDispatch();
  const [draftSheetData, setDraftSheetData] = useState(null);
  const [draftSheetLoading, setLoading] = useState(true);

  const getDraftSheetsData = async (projectId) => {
    setLoading(true);
    setDraftSheetData(null);
    const data = await getDraftSheetsApi(projectId, dispatch);
    setDraftSheetData(data);
    setLoading(false);
  };

  return {
    getDraftSheetsData,
    draftSheetData,
    setDraftSheetData,
    draftSheetLoading,
  };
};
