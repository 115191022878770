import API from "../../utils/api/api";

export const getProjectList = async (stage, subStage, dispatch) => {
  try {
    const url = `v1/project?stage=${stage}&subStage=${subStage}`;

    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};
export const getspreedSheetApi = async (projectId, apiName, dispatch) => {
  try {
    const url = "v1/prelim/" + projectId + apiName;

    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const getmetaDataApi = async (projectId, data, dispatch) => {
  try {
    const url = "v1/file/meta-data/" + projectId;

    const result = await API({
      method: "POST",
      data: data,
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};
export const getDraftDataApi = async (projectId, apiName, dispatch) => {
  try {
    const url = `v1/prelim/${projectId}/${apiName}/draft`;
    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const saveDraftDataApi = async (projectId, data, dispatch) => {
  try {
    const url = `v1/prelim/${projectId}/ratios/draft`;
    const result = await API({
      method: "POST",
      path: url,
      data: data,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};
