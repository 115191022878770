import { createSlice } from "@reduxjs/toolkit";
import {
  EMPTY_ACTIVITY_CONFIG,
  EMPTY_GENERIC_ERROR_DATA,
  EMPTY_LOADER_CONFIG,
  EMPTY_NOTIFICATION_CONFIG,
} from "../utils/constants";
import { DEFAULT_ROLE } from "../utils/roleBaseAccess/rolebaseRoutes";

const initialState = {
  token: "",
  userData: {},
  errorData: EMPTY_GENERIC_ERROR_DATA,
  editDefaultPrompts: false,
  activityMasterData: {},
  currentActivity: EMPTY_ACTIVITY_CONFIG,
  notification: EMPTY_NOTIFICATION_CONFIG,
  loader: EMPTY_LOADER_CONFIG,
  timerShowModel: true,
  userRole: [],
  staffingStep: {},
  accessJson: {},
};

// Create a slice with reducers
export const AriesAuditor = createSlice({
  name: "fiscalix",
  initialState,
  reducers: {
    // Update the correct field to 'token'
    setToken: (state, action) => {
      state.token = action.payload.token;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setError: (state, action) => {
      state.errorData = action.payload;
    },
    logout: (state, action) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    toggleDefaultPrompts: (state, action) => {
      state.editDefaultPrompts = action.payload;
    },
    setActivityMasterData: (state, action) => {
      state.activityMasterData = action.payload;
    },
    setCurrentActivity: (state, action) => {
      state.currentActivity = action.payload;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setloader: (state, action) => {
      state.loader = action.payload;
    },
    setTimerPopUp: (state, action) => {
      state.timerShowModel = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setStaffingStepData: (state, action) => {
      state.staffingStep = action.payload;
    },
    setAccessJson: (state, action) => {
      state.accessJson = action.payload;
    },
    resetFiscalixData: () => initialState,
  },
});

// Export actions from the slice
export const {
  setToken,
  setUserData,
  setError,
  resetFiscalixData,
  toggleDefaultPrompts,
  setActivityMasterData,
  setCurrentActivity,
  setNotification,
  setloader,
  setTimerPopUp,
  setUserRole,
  logout,
  setStaffingStepData,
  setAccessJson,
} = AriesAuditor.actions;

// Export the reducer generated by createSlice
export default AriesAuditor.reducer;
