import "./buttonGroup.scss";
import { Input } from "antd";

const ButtonGroupFooter = ({ buttonGroupObject, className }) => {
  return (
    <div className={`${className} button-group`}>
      <div className="left-panel">
        {buttonGroupObject?.left?.map((item) => {
          return (
            <button
              className={`${item.className} button`}
              key={item.text}
              onClick={item.fn}
              disabled={item.disabled}
            >
              {item.imageLink && <img src={item.imageLink} alt="" />}
              {item.text}
            </button>
          );
        })}
      </div>
      {buttonGroupObject?.center && (
        <div className="center-panel">
          <div className="finalize-box">
            <label className="finalize-label">
              {buttonGroupObject.center.label}
            </label>{" "}
            <Input
              className="finalize-input focus-input"
              onChange={buttonGroupObject.center.onChangeFn}
              value={buttonGroupObject.center.inputValue}
              disabled={buttonGroupObject?.center?.inputDisabled}
              placeholder="Enter Name"
            />
            <button
              disabled={buttonGroupObject.center.disabled}
              className="back-button button"
              onClick={buttonGroupObject.center.submitButtonFn}
            >
              {buttonGroupObject.center.buttonText}
            </button>
          </div>
        </div>
      )}

      <div className="right-panel">
        {buttonGroupObject?.right?.map((item) => {
          return (
            <button
              className={`${item.className} button`}
              key={item.text}
              onClick={item.fn}
              disabled={item.disabled}
            >
              {item.imageLink && <img src={item.imageLink} alt="" />}
              {item.text}
              {item.rightImageLink && <img src={item.rightImageLink} alt="" />}
            </button>
          );
        })}
      </div>
    </div>
  );
};
export default ButtonGroupFooter;
