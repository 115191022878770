import React, { useEffect } from "react";
import { Form, Select } from "antd";

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  options,
  onSave,
  ...restProps
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editing && record) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [editing, record, dataIndex, form]);

  if (dataIndex !== "Mapping") {
    return <td {...restProps}>{children}</td>;
  }

  const handleSelect = (value) => {
    if (onSave) {
      onSave(record.key, value);
    }
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form form={form}>
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please select ${title}!`,
              },
            ]}
          >
            <Select
              style={{ maxWidth: "500px" }}
              options={options.map((option) => ({ label: option, value: option }))}
              onChange={handleSelect}
              defaultValue={record[dataIndex]}
              autoFocus
              dropdownStyle={{ maxWidth: "500px" }}
            />
          </Form.Item>
        </Form>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24, cursor: "pointer", maxWidth: "500px" }}
        >
          {children}
        </div>
      )}
    </td>
  );
};

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <tr {...props} />
    </Form>
  );
};
