import React, { useState } from "react";
import { Card, Modal, Radio, Space } from "antd";

const DecoderDataUploadModal = ({
  showModel,
  handleClose,
  uploadTrialBalanceSheet,
  uploadDecoderSheet,
}) => {
  // State to track the selected option
  const [selectedOption, setSelectedOption] = useState(null);

  const handleCancel = () => {
    handleClose();
  };

  const handleProceed = () => {
    if (selectedOption === 1) {
      uploadDecoderSheet(); // Execute uploadDecoderSheet for option 1
    } else if (selectedOption === 2) {
      uploadTrialBalanceSheet(); // Execute uploadTrialBalanceSheet for option 2
    }
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value); // Update selected option
  };

  return (
    <Modal
      open={showModel}
      centered
      width={600}
      destroyOnClose
      style={{ overflow: "auto" }}
      onCancel={handleCancel}
      footer={[]}
      rootClassName="decoder-flow-selector-wrapper"
    >
      <div className="decoder-flow-selector-model">
        <div className="decoder-flow-selector-title">Decoder Flow Selector</div>
        <div className="decoder-flow-selector-details">
          <Radio.Group onChange={handleRadioChange}>
            <Space direction="vertical">
              <Card
                className={`radio-wrapper ${selectedOption === 1 ? "selected" : ""}`}
              >
                <div className="info">
                  <p className="flow-heading">Unpublished Financials</p>
                  <p className="flow-description">
                    If you have draft financial statement select this option
                  </p>
                </div>
                <Radio value={1} />
              </Card>

              <Card
                className={`radio-wrapper ${selectedOption === 2 ? "selected" : ""}`}
              >
                <div className="info">
                  <p className="flow-heading">Trial Balance with Fiscalix Mapping</p>
                  <p className="flow-description">
                    If you have draft trial balance select this option
                  </p>
                </div>
                <Radio value={2} />
              </Card>
            </Space>
          </Radio.Group>
        </div>

        <div className="decoder-flow-selector-footer">
          <button className="button white-button" onClick={handleCancel}>
            Cancel
          </button>
          <button
            className="primary-button button"
            onClick={handleProceed}
            disabled={selectedOption === null} // Disable if no option is selected
          >
            Proceed to Upload
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DecoderDataUploadModal;
