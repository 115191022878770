import React from "react";
import BreadCrumb from "../../../components/breadcrumbComponent";
import { Tabs } from "antd";
import { removeUnderScore } from "../../utils";
import "./globalHeader.scss";

const { TabPane } = Tabs;

export const MicroappHeader = ({
  titleConfig = {},
  actions = null,
  displayEngagement = null,
  tabsConfig = [], // Array of { key: string, label: string }
  activeTabKey = null, // Active tab key
  onTabChange = () => {}, // Callback for tab change
}) => {
  return (
    <div
      className="microapp-header"
      style={tabsConfig.length > 0 ? { paddingBottom: "0" } : {}}
    >
      <BreadCrumb
        mainTitle={titleConfig.parent}
        subTitle={titleConfig.subtitle}
        titleClassName={titleConfig.className}
        mainPath={titleConfig.mainPath}
        customTitle={titleConfig?.customTitle}
      />
      <div className="title-div">
        <div className="title-text">
          {titleConfig.customTitle
            ? removeUnderScore(titleConfig?.customTitle)
            : titleConfig.mainPath}
        </div>
        {displayEngagement}
        {actions && <div className="title-actions">{actions}</div>}
      </div>
      {tabsConfig.length > 0 && (
        <Tabs
          activeKey={activeTabKey}
          onChange={onTabChange}
          className="custom-tabs"
          tabBarStyle={{
            borderBottom: "none",
          }}
        >
          {tabsConfig.map((tab) => (
            <TabPane tab={tab.label} key={tab.key} />
          ))}
        </Tabs>
      )}
    </div>
  );
};
