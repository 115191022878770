import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import LeftMenu from "./components/leftMenu";
import ErrorModel from "./components/model/genricError";
import Loader from "./utils/components/loader";
import NotificationComponent from "./utils/components/notification";
import { useTokenValidation } from "./utils/hook";
import { roleAccess } from "./utils/roleBaseAccess/rolebaseRoutes";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  defaultAppPermissions,
  getUserPermissions,
} from "./utils/roleBaseAccess/rolebaseRoutes";
import DecoderV2 from "./pages/decoder-v2";
import ChatBot from "./components/chatBot";

// Lazy loading components
const Header = lazy(() => import("./components/header"));
const Login = lazy(() => import("./pages/login"));
const RegisteredEmail = lazy(
  () => import("./pages/login/resetPassword/registerEmail")
);
const ResetPassword = lazy(
  () => import("./pages/login/resetPassword/resetPassword")
);
const AidaIntro = lazy(() => import("./pages/login/aidaIntro"));
const TermsAndConditions = lazy(() => import("./pages/login/terms/termsConditions"));
const MicroApp = lazy(() => import("./pages/microApp"));
const Decoder = lazy(() => import("./pages/decoder-v2"));
const Home = lazy(() => import("./pages/home"));
const ChatHome = lazy(() => import("./pages/chatHome"));
const OnBoarding = lazy(() => import("./pages/onBoarding"));
const EightkAnalyser = lazy(() => import("./pages/8kAnalyser"));
const Ratios = lazy(() => import("./pages/ratiosVarianceMateriality/ratios"));
const Variance = lazy(() => import("./pages/ratiosVarianceMateriality/variance"));
const Materiality = lazy(
  () => import("./pages/ratiosVarianceMateriality/materiality")
);
const Profile = lazy(() => import("./pages/profile"));
const Budgeting = lazy(() => import("./pages/budgeting"));
const StaffingV2 = lazy(() => import("./pages/staffing-v2"));
const AuditPlanner = lazy(() => import("./pages/auditPlanner"));
const Settings = lazy(() => import("./pages/settings"));
const Timesheet = lazy(() => import("./pages/timesheet"));
const Clients = lazy(() => import("./pages/clients"));
const Policies = lazy(() => import("./pages/policies"));
const PracticeManager = lazy(() => import("./pages/practiceManager"));
const MasterManager = lazy(() => import("./pages/masterManagerData"));
const EmployeeDirectory = lazy(
  () => import("./pages/masterManagerData/staffing/employeeDirectory")
);
const EmployeeByEngagement = lazy(
  () => import("./pages/practiceManager/emploeeDashboard/empUtilEngagements")
);
const EmployeeAvailability = lazy(
  () => import("./pages/practiceManager/emploeeDashboard/empAvailability")
);
const EmployeeUtilization = lazy(
  () => import("./pages/practiceManager/emploeeDashboard/empUtil")
);
const EngagmentDocuments = lazy(
  () => import("./pages/practiceManager/engagementDashboard/engagementDocuments")
);
const EngagementDirectory = lazy(
  () => import("./pages/masterManagerData/engagements/engagementDirectory")
);
const TimesheetDirectory = lazy(
  () => import("./pages/masterManagerData/staffing/timeSheet")
);
const ClientDirectory = lazy(
  () => import("./pages/masterManagerData/clients/clientDirectory")
);
const BillingRate = lazy(
  () => import("./pages/masterManagerData/staffing/billingRates")
);
const ClientPocDirectory = lazy(
  () => import("./pages/masterManagerData/clients/clientPoc")
);
const EngagementTypes = lazy(
  () => import("./pages/masterManagerData/engagements/engagementTypes")
);
const ActivityListing = lazy(
  () => import("./pages/masterManagerData/engagements/activityListing")
);
const EngagementPlanner = lazy(() => import("./pages/engagementPlanner"));
const UnauthorizedPage = lazy(() => import("./pages/unauthorized"));
const AuditClosure = lazy(() => import("./pages/auditClosure"));
const AuditReport = lazy(() => import("./pages/auditReport"));
const AuditReview = lazy(() => import("./pages/auditReview"));
const AuditPrograms = lazy(() => import("./pages/programs"));
const CashCashEquivalents = lazy(
  () => import("./pages/programs/programList/cashCashEquivalents")
);

const JETesting = lazy(() => import("./pages/programs/programList/jeTesting"));

const AccountReceivable = lazy(
  () => import("./pages/programs/programList/accountReceivable")
);

const Risk = lazy(() => import("./pages/risk"));

function App() {
  const userRole = useSelector((state) => state.userRole);
  const accessJson = useSelector((state) => state.accessJson);
  const [userPermissions, setUserPermissions] = useState({});
  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  const showChatBot = storedUserData?.token ? true : false;

  useEffect(() => {
    const permissions = getUserPermissions(userRole, accessJson);
    setUserPermissions(permissions);
  }, [userRole, accessJson]);

  const roleBasedRoutes = [
    { path: "/", component: MicroApp, allowedRoles: true },
    { path: "/home", component: Home, allowedRoles: true },
    { path: "/chat", component: ChatHome, allowedRoles: true },
    {
      path: "/onboarding",
      component: OnBoarding,
      allowedRoles: userPermissions?.Onboarding,
    },
    {
      path: "/8kanalyzer",
      component: EightkAnalyser,
      allowedRoles: userPermissions?.["8KAnalyzer"],
    },
    {
      path: "/ratios",
      component: Ratios,
      allowedRoles: userPermissions?.Ratio,
    },
    {
      path: "/variance",
      component: Variance,
      allowedRoles: userPermissions?.Variance,
    },
    {
      path: "/materiality",
      component: Materiality,
      allowedRoles: userPermissions?.Materiality,
    },
    { path: "/profile", component: Profile, allowedRoles: true },
    {
      path: "/budgeting",
      component: Budgeting,
      allowedRoles: userPermissions?.Budgeting,
    },
    {
      path: "/staffing",
      component: StaffingV2,
      allowedRoles: userPermissions?.Staffing,
    },
    {
      path: "/audit-planner",
      component: AuditPlanner,
      allowedRoles: userPermissions?.AuditPlanner,
    },
    {
      path: "/decoder",
      component: Decoder,
      allowedRoles: userPermissions?.Decoder,
    },
    { path: "/settings", component: Settings, allowedRoles: true },
    { path: "/timesheet", component: Timesheet, allowedRoles: true },
    {
      path: "/engagement-documents",
      component: EngagmentDocuments,
      allowedRoles: true,
    },
    {
      path: "/engagement-planner",
      component: EngagementPlanner,
      allowedRoles: true,
    },
    { path: "/clients", component: Clients, allowedRoles: true },
    {
      path: "/policies",
      component: Policies,
      allowedRoles: userPermissions?.Policies,
    },
    {
      path: "/practice-manager",
      component: PracticeManager,
      allowedRoles: true,
    },
    { path: "/master-manager", component: MasterManager, allowedRoles: true },
    {
      path: "/audit-closure",
      component: AuditClosure,
      allowedRoles: userPermissions?.ClosingProcess,
    },
    {
      path: "/audit-report",
      component: AuditReport,
      allowedRoles: userPermissions?.AuditReport,
    },
    {
      path: "/audit-review",
      component: AuditReview,
      allowedRoles: userPermissions?.Review,
    },
    {
      path: "/programs",
      component: AuditPrograms,
      allowedRoles: userPermissions?.Programs,
    },
  ];

  const ProtectedRoute = ({ component: Component, allowedRoles }) => {
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    const isAuthenticated = storedUserData?.token ? true : false;
    const location = useLocation();
    const { validateToken } = useTokenValidation();

    useEffect(() => {
      validateToken();
    }, [location.pathname]);

    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    }

    if (allowedRoles === false) {
      return <Navigate to="/unauthorized" replace />;
    }

    return (
      <>
        <Header />
        <LeftMenu />
        <Component />
      </>
    );
  };

  return (
    <div className="App">
      <ErrorModel />
      <Loader />
      {showChatBot && <ChatBot />}
      <NotificationComponent />
      <Router>
        <Suspense
          fallback={
            <Spin
              spinning={true}
              indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
              fullscreen
              className="fullscreen-loader"
            />
          }
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/login/forgot-password" element={<RegisteredEmail />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/welcome" element={<AidaIntro />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />

            {roleBasedRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <ProtectedRoute
                    component={route.component}
                    allowedRoles={route.allowedRoles}
                  />
                }
              />
            ))}

            <Route path="/practice-manager">
              <Route
                path="employee-directory"
                element={
                  <ProtectedRoute
                    component={EmployeeDirectory}
                    allowedRoles={true}
                  />
                }
              />
              <Route
                path="employee-engagements"
                element={
                  <ProtectedRoute
                    component={EmployeeByEngagement}
                    allowedRoles={true}
                  />
                }
              />
              <Route
                path="employee-availability"
                element={
                  <ProtectedRoute
                    component={EmployeeAvailability}
                    allowedRoles={true}
                  />
                }
              />
              <Route
                path="employee-utilization"
                element={
                  <ProtectedRoute
                    component={EmployeeUtilization}
                    allowedRoles={true}
                  />
                }
              />
              <Route
                path="engagement-directory"
                element={
                  <ProtectedRoute
                    component={EngagementDirectory}
                    allowedRoles={true}
                  />
                }
              />
              <Route
                path="timesheet-directory"
                element={
                  <ProtectedRoute
                    component={TimesheetDirectory}
                    allowedRoles={true}
                  />
                }
              />
            </Route>

            <Route
              path="risk"
              element={<ProtectedRoute component={Risk} allowedRoles={true} />}
            />

            <Route path="/programs">
              <Route
                path="CASH_AND_CASH_EQUIVALENTS"
                element={
                  <ProtectedRoute
                    component={CashCashEquivalents}
                    allowedRoles={true}
                  />
                }
              />
              <Route
                path="ACCOUNTS_RECEIVABLE"
                element={
                  <ProtectedRoute
                    component={AccountReceivable}
                    allowedRoles={true}
                  />
                }
              />
              <Route
                path="JOURNAL_ENTRY_TESTING"
                element={
                  <ProtectedRoute component={JETesting} allowedRoles={true} />
                }
              />
            </Route>

            <Route path="/master-manager">
              <Route
                path="client-directory"
                element={
                  <ProtectedRoute component={ClientDirectory} allowedRoles={true} />
                }
              />
              <Route
                path="employee-directory"
                element={
                  <ProtectedRoute
                    component={EmployeeDirectory}
                    allowedRoles={true}
                  />
                }
              />
              <Route
                path="engagement/engagement-directory"
                element={
                  <ProtectedRoute
                    component={EngagementDirectory}
                    allowedRoles={true}
                  />
                }
              />
              <Route
                path="billing-rates"
                element={
                  <ProtectedRoute component={BillingRate} allowedRoles={true} />
                }
              />
              <Route
                path="client-directory/poc"
                element={
                  <ProtectedRoute
                    component={ClientPocDirectory}
                    allowedRoles={true}
                  />
                }
              />
              <Route
                path="engagement-types"
                element={
                  <ProtectedRoute component={EngagementTypes} allowedRoles={true} />
                }
              />
              <Route
                path="audit-activity"
                element={
                  <ProtectedRoute component={ActivityListing} allowedRoles={true} />
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
