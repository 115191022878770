import Aries from "../locale/en-Us.json";
export const DecoderJson = [
  {
    step: 1,
    fields: [
      {
        name: "welcome",
        label: Aries.DECODER_WELCOME_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 2,
    fields: [
      {
        name: "listWithRadio",
        label: Aries.DECODER_LIST_OF_COMPANY_SCREEN_TILE,
      },
    ],
  },
  {
    step: 3,
    fields: [
      {
        name: "listsWithFileUpload",
        label: Aries.DECODER_HISTORIC_8K_10K_SCREEN_TILE,
        subTitle: Aries.ONBOARDING_HISTORIC_8K_10K_SCREEN_SUB_TILE,
      },
    ],
  },
  {
    step: 4,
    fields: [
      {
        name: "loadingProgessBar",
        label: Aries.DECODER_PROGESSBAR_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 5,
    fields: [
      {
        name: "balanceSheet",
        label: Aries.DECODER_BALANCE_SHEET_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 6,
    fields: [
      {
        name: "incomeSheet",
        label: Aries.DECODER_BALANCE_SHEET_SCREEN_TITLE,
      },
    ],
  },
];

export const UploadSheetDecoderJson = [
  {
    step: 1,
    fields: [
      {
        name: "welcome",
        label: Aries.DECODER_WELCOME_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 2,
    fields: [
      {
        name: "listWithRadio",
        label: Aries.DECODER_LIST_OF_COMPANY_SCREEN_TILE,
      },
    ],
  },
  {
    step: 3,
    fields: [
      {
        name: "listsWithFileUpload",
        label: Aries.DECODER_HISTORIC_8K_10K_SCREEN_TILE,
        subTitle: Aries.ONBOARDING_HISTORIC_8K_10K_SCREEN_SUB_TILE,
      },
    ],
  },
  {
    step: 4,
    fields: [
      {
        name: "loadingProgessBar",
        label: Aries.DECODER_PROGESSBAR_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 5,
    fields: [
      {
        name: "selectSheetOptions",
        label: Aries.DECODER_SELECT_SHEET_TITLE,
      },
    ],
  },
  {
    step: 6,
    fields: [
      {
        name: "displayBalanceExcelwithDetails",
        label: Aries.DECODER_SHEET_REVIEW_TITLE,
      },
    ],
  },
  {
    step: 7,
    fields: [
      {
        name: "loadingProgessBar",
        label: Aries.DECODER_PROGESSBAR_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 8,
    fields: [
      {
        name: "balanceSheet",
        label: Aries.DECODER_BALANCE_SHEET_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 9,
    fields: [
      {
        name: "incomeSheet",
        label: Aries.DECODER_BALANCE_SHEET_SCREEN_TITLE,
      },
    ],
  },
];

export const decoderJsonV2 = [
  {
    step: 1,
    fields: [
      {
        name: "welcome",
        label: Aries.DECODER_WELCOME_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 2,
    fields: [
      {
        name: "listWithRadio",
        label: Aries.DECODER_LIST_OF_COMPANY_SCREEN_TILE,
      },
    ],
  },
  {
    step: 3,
    fields: [
      {
        name: "decoderTrackerTable",
      },
    ],
  },
];

export const decoderTrialBalanceSheetJson = [
  {
    step: 4,
    fields: [
      {
        name: "uploadTemplateForTrailBalanceSheet",
        label: Aries.DECODER_BALANCE_SHEET_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 5,
    fields: [
      {
        name: "loadingProgessBar",
        label: Aries.DECODER_PROGESSBAR_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 6,
    fields: [
      {
        name: "trialBalanceWithFiscalixMapping",
        label: Aries.DECODER_BALANCE_SHEET_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 7,
    fields: [
      {
        name: "draftBalanceAndIncomeSheet",
        label: Aries.DECODER_BALANCE_SHEET_SCREEN_TITLE,
      },
    ],
  },
];

export const balanceSheetJson = [
  {
    step: 4,
    fields: [
      {
        name: "loadingProgessBar",
        label: Aries.DECODER_PROGESSBAR_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 5,
    fields: [
      {
        name: "balanceSheet",
        label: Aries.DECODER_BALANCE_SHEET_SCREEN_TITLE,
      },
    ],
  },
];
export const incomeSheetJson = [
  {
    step: 4,
    fields: [
      {
        name: "loadingProgessBar",
        label: Aries.DECODER_PROGESSBAR_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 5,
    fields: [
      {
        name: "incomeSheet",
        label: Aries.DECODER_BALANCE_SHEET_SCREEN_TITLE,
      },
    ],
  },
];

export const UploadSheetDecoderJsonV2 = [
  {
    step: 1,
    fields: [
      {
        name: "welcome",
        label: Aries.DECODER_WELCOME_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 2,
    fields: [
      {
        name: "listWithRadio",
        label: Aries.DECODER_LIST_OF_COMPANY_SCREEN_TILE,
      },
    ],
  },

  {
    step: 3,
    fields: [
      {
        name: "decoderTrackerTable",
        label: Aries.DECODER_HISTORIC_8K_10K_SCREEN_TILE,
        subTitle: Aries.ONBOARDING_HISTORIC_8K_10K_SCREEN_SUB_TILE,
      },
    ],
  },
  {
    step: 4,
    fields: [
      {
        name: "uploadDecoderSheet",
        label: Aries.DEODER_UPLOAD_SHEET_TITLE,
      },
    ],
  },
];

export const decoderGuidanceJson = [
  {
    step: 4,
    fields: [
      {
        name: "selectSheetOptions",
        label: Aries.DECODER_SELECT_SHEET_TITLE,
      },
    ],
  },
  {
    step: 5,
    fields: [
      {
        name: "displayBalanceExcelwithDetails",
        label: Aries.DECODER_SHEET_REVIEW_TITLE,
      },
    ],
  },
];
export const balanceSheetGuidanceJson = [
  {
    step: 6,
    fields: [
      {
        name: "loadingProgessBar",
        label: Aries.DECODER_PROGESSBAR_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 7,
    fields: [
      {
        name: "balanceSheet",
        label: Aries.DECODER_BALANCE_SHEET_SCREEN_TITLE,
      },
    ],
  },
];
export const incomeSheetGuidanceJson = [
  {
    step: 6,
    fields: [
      {
        name: "loadingProgessBar",
        label: Aries.DECODER_PROGESSBAR_SCREEN_TITLE,
      },
    ],
  },
  {
    step: 7,
    fields: [
      {
        name: "incomeSheet",
        label: Aries.DECODER_BALANCE_SHEET_SCREEN_TITLE,
      },
    ],
  },
];
